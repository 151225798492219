import React from 'react'
import {useTranslation} from 'react-i18next'
import {Popover} from 'react-tiny-popover'

import AbandonedFiles from '../../svg/AbandonedFiles'
import {EFolderStatus} from '../../../../domain/Folder/EFolderStatus'
import {ERole} from '../../../../domain/User/Erole'
import {PropsActions} from './types'

const FolderActions: React.FC<PropsActions> = ({
  FolderDetail,
  role,
  handleEdit,
  handleDownload,
  handleDocuSignHistory,
  handleManageDossier,
  setIsPopoverOpen,
  isPopoverOpen,
  toggle,
  allowDownloadDocument,
  setError,
  type
}) => {
  const {t} = useTranslation()
  const statusHideAllButton: string[] = [EFolderStatus.Canceled, EFolderStatus.Rejected]
  const roleHideButtonDeleted: string[] = [ERole.Client, ERole.Prospect, ERole.Back_office_user, ERole.ManageDossier]
  const roleHideButtonModify: string[] = [ERole.Back_office_user, ERole.ManageDossier]
  const roleHideButtonHistorySignature: string[] = [ERole.Client, ERole.Prospect]
  const roleHideManageDossier: string[] = [ERole.Client, ERole.Prospect, ERole.Back_office_user]
  const statusHideButtonDeleted: string[] = [EFolderStatus.Signed]

  return (
    <div className="flex">
      {!statusHideAllButton.includes(FolderDetail.status) && (
        <>
          {allowDownloadDocument && (!roleHideButtonModify.includes(role) && type !== 'manage' &&
            (FolderDetail.status === 'signature' || FolderDetail.status === 'signed')) && (
            <button onClick={handleDownload}
                    className="button-reset button-primary--outline button--medium button--width-fit u-mrb"
                    data-id={FolderDetail.id}
            >
              {t('button.download-document')}
            </button>
          )}

          {(!roleHideButtonHistorySignature.includes(role) && type !== 'manage' &&
            (FolderDetail.status === EFolderStatus.Signature || FolderDetail.status === EFolderStatus.Signed)) && (
            <button onClick={handleDocuSignHistory}
                    className="button-reset button-primary--outline button--medium button--width-fit u-mrb"
                    data-id={FolderDetail.id}
            >
              {t('docusign.history')}
            </button>
          )}

          {(!roleHideButtonModify.includes(role) && type !== 'manage' &&
            (FolderDetail.status !== EFolderStatus.Signature && FolderDetail.status !== EFolderStatus.Signed)) && (
            <button onClick={handleEdit}
                    className="button-reset button-primary--outline button--medium button--width-fit u-mrb"
                    data-id={FolderDetail.id}
            >
              {t('button.modify')}
            </button>
          )}
          {roleHideButtonModify.includes(role) && type !== 'manage' && (
            <button onClick={handleEdit}
                    className="button-reset button-primary--outline button--medium button--width-fit u-mrb"
                    data-id={FolderDetail.id}
            >
              {t('button.view')}
            </button>
          )}
          {(!roleHideButtonDeleted.includes(role) && !statusHideButtonDeleted.includes(FolderDetail.status)) && type !== 'manage' && (
            <Popover
              isOpen={isPopoverOpen}
              positions={['right']}
              align={'center'}
              padding={10}
              content={<div className="box-helpers">{t('folder.abandon-folder')}</div>}
            >
              <button data-id={FolderDetail.id}
                      onMouseEnter={() => setIsPopoverOpen(true)}
                      onMouseLeave={() => setIsPopoverOpen(false)}
                      className="button-reset button--width-fit button--files-state button--abandon"
                      onClick={() => {
                        setError(false)
                        toggle()
                      }}
              >
                <AbandonedFiles color="var(--ink-6)"/>
              </button>
            </Popover>
          )}

          {!roleHideManageDossier.includes(role) && type === 'manage' &&
            <button onClick={handleManageDossier}
                    className="button-reset button-primary--outline button--medium button--width-fit u-mrb"
                    data-id={FolderDetail.id}>
              {t('button.manage-dossier')}
            </button>
          }
        </>
      )}
    </div>
  )
}

export default FolderActions
