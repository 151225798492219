import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs'

import {IntegrationStateHistory} from '../../../domain/IntegrationStateHistory/IntegrationStateHistory'
import ValidationState from '../validation/ValidationState'

interface Props {
  integrationStateHistory: IntegrationStateHistory[],
}

const IntegrationStateHistoryBlock: React.FC<Props> = ({integrationStateHistory}) => {
  const {t} = useTranslation()

  return (
    <div className="box-elevations box-elevations--base box-elevations--mb u-mxAuto">
      <h2 className="help-wrapper u-mbs">
        {t('validation.actions')}
      </h2>
      <table className="table-validation u-mtl">
        <thead>
        <tr>
          <td width="80px" align="center" className="u-txt-bold">{t('validation.state')}</td>
          <td className="u-txt-bold">{t('validation.date')}</td>
          <td className="u-txt-bold">{t('validation.user')}</td>
          <td className="u-txt-bold">{t('validation.notes')}</td>
        </tr>
        </thead>
        <tbody>
        {(integrationStateHistory.map((integrationStateHistoryItem) => {
          return <tr>
            <td align={'center'}><ValidationState state={integrationStateHistoryItem.state}/></td>
            <td className={`date`}>{dayjs(integrationStateHistoryItem.createdAt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH[h]mm')}</td>
            <td>{integrationStateHistoryItem.user}</td>
            <td>{integrationStateHistoryItem.reason}</td>
          </tr>
        }))}
        </tbody>
      </table>
    </div>
  );
};

export default IntegrationStateHistoryBlock;
