import React, {FunctionComponent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {RouteComponentProps} from 'react-router-dom';
import {Link} from 'react-router-dom';

import ManageFoldersComponent from '../component/folder/ManageFoldersComponent'
import FooterComponent from "../component/footer/FooterComponent";
import HeaderLightComponent from "../component/header/HeaderLightComponent";

import Back from '../component/svg/Back';

type Params = {
  state: string
  currentPage: string
};

const ManageFolder: FunctionComponent<RouteComponentProps<Params>> = ({match}) => {
  const { t } = useTranslation()

  const [term, setTerm] = useState<string>('');

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTerm((e.target.value).toLowerCase());
  }

  const title = match?.params?.state !== undefined ? t(`dashboard.integration-state.${match.params.state}`) : t('common.folders')

  const [role] = useState<string>(localStorage.getItem('role') ?? '');

  if (role !== 'manage_dossier') {
    window.location.pathname = '/'
  }

  return (
    <div>
      <HeaderLightComponent />
          <>
            <div className="wrap u-mxAuto">
              <div className="flex-container">
                <div className="col-md-12">
                  <Link to="/tableau-de-bord">
                    <Back color="var(--ink-1)" />
                    <span className="u-txt-size-s u-txt-color-ink-1 u-mxs">{t('button.back')}</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="wrap u-mxAuto">
              <div className="flex-container">
                <div className="col-md-8">
                  <h2 className="h3 u-mbs" dangerouslySetInnerHTML={{__html: title}}/>
                </div>
                <div className="col-md-4">
                  <input type="text" onChange={e => handleSearchInputChange(e)}
                         style={{boxShadow: `0px 5px 8px rgb(0 0 0 / 10%)`}} placeholder={t('common.search')}
                         className="button-search" />
                </div>
              </div>
            </div>
            <div className="min-height-content-folder">
              <div className="box-elevations box-elevations--base box-elevations--max-heigt-initial box-elevations--mb u-mxAuto">
                <ManageFoldersComponent term={term} state={match?.params?.state} />
              </div>
            </div>
          </>
      <FooterComponent />
    </div>
  );
}

export default ManageFolder;
