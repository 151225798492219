export enum ERole {
    Client = 'client',
    Prospect = 'prospect',
    Back_office_user = 'back_office_user',
    Manager = 'manager',
    Partner = 'partner',
    Advisor = 'advisor',
    ManageDossier = 'manage_dossier',
}

const roleBackOfficeList: string[] = [ERole.Back_office_user, ERole.ManageDossier];
export {roleBackOfficeList}