import React, {FunctionComponent, useEffect, useState} from "react";
import FetchFolderGateway from "../../../gateway/Folder/FetchFolderGateway";
import Client from "../../../domain/Client/Client";
import FetchClientGateway from "../../../gateway/Client/FetchClientGateway";
import {useHistory} from "react-router";
import CreateUseCase from "../../../useCase/Folder/CreateUseCase";
import storage from "../../util/storage";
import {useTranslation} from 'react-i18next';

type Props = {
  term: string
}

const ClientsComponent: FunctionComponent<Props> = ({term}) => {
  const history = useHistory()
  const { t } = useTranslation()

  const [clients, setClients] = useState<Client[] | null>(null);
  const createUseCase = new CreateUseCase(new FetchFolderGateway());

  function createFolder(clientId: number) {
    createUseCase.execute(storage.getFormId(), clientId)
      .then(() => {
        history.push(`/form/`)
      })
  }

  useEffect(() => {
    (new FetchClientGateway()).getClients().then((clients) => {
      if (null !== clients) {
        const clientsFiltered = clients.filter(client =>
          (client?.first_name)?.toLowerCase().includes(term)
           || (client?.last_name)?.toLowerCase().includes(term)
           || (client?.username)?.toLowerCase().includes(term)
        )
        setClients(clientsFiltered);
      }
    })
  }, [term])

  return (
    <div className="wrap u-mxAuto">
      <div className="flex-container u-mtl">
        <div className="col-md-12">
          {clients?.map((client) => {
            return (
              <div className="list__line" key={client.id}>
                <div>
                  <div className="h5 u-mb0">{client.last_name} {client.first_name}</div>
                  <div className="u-txt-size-s">{t('common.client-number')} : {client.username}</div>
                </div>
                <div>
                  <a href="#" onClick={() => {createFolder(client.id)}} className="create-folder button button-primary--outline button--medium button--width-fit">
                    {t('button.create-folder')}
                  </a>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ClientsComponent;
